import { Injectable } from '@angular/core';
import CustomStore from "devextreme/data/custom_store";
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import notify from 'devextreme/ui/notify';
import { jsToSQLFilter, replaceInFilter, getHttpHeaders } from 'app/services/commons';
import { saveAs } from 'file-saver';
import { AppConfig } from 'app/app.config';
import { Subject } from 'rxjs';
import DataSource from 'devextreme/data/data_source';



/**
 * Saves a file by opening file-save-as dialog in the browser
 * using file-save library.
 * @param blobContent file content as a Blob
 * @param fileName name file should be saved as
 */
export const saveFile = (blobContent: Blob, fileName: string) => {
  const blob = new Blob([blobContent], { type: 'application/octet-stream' });

//  console.log("fileName" + fileName);
  saveAs(blob, fileName);
};

/**
* Derives file name from the http response
* by looking inside content-disposition
* @param res http Response
*/
export const getFileNameFromResponseContentDisposition = (res: Response) => {
 // console.log("getFileNameFromResponseContentDisposition", res);
  const contentDisposition = res.headers.get('content-disposition') || '';
  const matches = /filename=([^;]+)/ig.exec(contentDisposition);
  if (!matches) {
    var qsetype = res.type;

    return 'SansTitre';
  }
  const fileName = (matches[1] || 'untitled').trim();
  return fileName;
};




export class Dossier {
  id: number;
  nom: string;
  parent: number;
  status: number;
  doctype: number;
}


let _dossiersDatSource : Array<Dossier> = [
  { id: 1, nom: "Documents", parent: 0, status:-1, doctype: 0 },
  { id: 2, nom: "Administration", parent: 1, status:-1, doctype: 0 },
  { id: 3, nom: "FE de référence", parent: 2, status:1 , doctype: 1},
  { id: 4, nom: "PJ d'exigence future", parent: 2, status:1, doctype: 3 },
  { id: 5, nom: "PJ version d'exigence" , parent: 2, status:1, doctype: 2 },
  { id: 6, nom: "Documents admin de référence", parent: 2, status:1, doctype: 6 },
  { id: 7, nom: "Rapports pour l'administrateur", parent: 2, status:1, doctype: 8 },
  { id: 8, nom: "Rapport réponses de questionnaires", parent: 2, status:1, doctype: 12 },

/*
  { id: 10, nom: "Clients", parent: 1, status:-1, doctype: 0 },
  { id: 11, nom: "Associé à une usage de référence", parent: 10, status:2, doctype: 4 },
  { id: 12, nom: "Associé à un site", parent: 10, status:2 , doctype: 5},
  { id: 13, nom: "Rapports client", parent: 10, status:2, doctype: 7 },
  { id: 14, nom: "Audits client", parent: 10, status:2, doctype: 9 },
  { id: 15, nom: "Rattachés à une exigence", parent:10, status:2, doctype: 10 },
  { id: 16, nom: "Doc client sur évaluation d'exigence", parent: 10, status:2, doctype: 11 },
 */
];



let _dossiersClientDatSource : Array<Dossier> = [
  { id: 10, nom: "Documents", parent: 0, status:-1, doctype: 0 },
  { id: 11, nom: "Associé à une usage de référence", parent: 10, status:2, doctype: 4 },
  { id: 12, nom: "Associé à un site", parent: 10, status:2 , doctype: 5},
  { id: 13, nom: "Rapports client", parent: 10, status:2, doctype: 7 },
  { id: 14, nom: "Audits client", parent: 10, status:2, doctype: 9 },
  { id: 15, nom: "Rattachés à une exigence", parent:10, status:2, doctype: 10 },
  { id: 16, nom: "Doc client sur évaluation d'exigence", parent: 10, status:2, doctype: 11 },

];



@Injectable({  providedIn: 'root' })
export class DocumentService {

  dataSource: any;
  feSiteDataSource: any;

  documentsSubject = new Subject<any>()

/*
  downloadFile(id) {
      const headers = new HttpHeaders();
      headers.append('Accept', 'application/octet-stream');
      headers.append('responseType', 'arraybuffer');
      headers.append('cache', 'false');
      this.http.get(('/api/document/download/' + id), { headers: headers })
        .toPromise()
        .then(response => this.saveToFileSystem(response));
    }



    private saveToFileSystem(response) {
      const contentDispositionHeader: string = response.headers.get('Content-Disposition');
      const parts: string[] = contentDispositionHeader.split(';');
      const filename = parts[1].split('=')[1];
      const blob = new Blob([response._body], { type: 'application/octet-stream' });
      saveAs(blob, filename);
    }
   */

  quotas(site_id: any) {
    return this.http.get<any>(AppConfig.settings.api+ "/api/document/quotas/" + site_id , { headers: getHttpHeaders() }).toPromise();
  }

  sitesWithByteConsumption(client_id: any) {
    return this.http.get<any>(AppConfig.settings.api + "/api/document/sites/" + client_id , { headers: getHttpHeaders() }).toPromise();
  }


  getDocumentExtension(doc_id: any) {
    return this.http.get<any>(AppConfig.settings.api + "/api/document/ext/" + doc_id , { headers: getHttpHeaders() }).toPromise();
  }

  obtenirValeurParametre(clef:string) {
    return this.http.get<any>(AppConfig.settings.api + "/api/parametre/?filter=" + encodeURIComponent(clef), { headers: getHttpHeaders() }).toPromise();
  }

  downloadFile(fileId: string, filename:string ) {

   // const options = new RequestOptions({ responseType: ResponseContentType.Blob });
    const options = {
      headers: new HttpHeaders({
        responseType:  'blob'
        })
      };

    var headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', '*')
      .set('Accept', 'application/octet-stream')
      .set('Access-Control-Allow-Methods', 'GET, POST, OPTIONS, PUT, PATCH, DELETE')
      .set('Access-Control-Allow-Headers', 'X-Requested-With, responseType,Origin, Content-Type,Accept, X-Auth-Token ,App-Auth,X-XSRF-TOKEN,Authorization')
      .set('Access-Control-Allow-Credentials', "true");

    const url = AppConfig.settings.api+ "/api/document/download/" + fileId;
    const url_name = AppConfig.settings.api+ "/api/document/titre/" + fileId;
    this.http.get(url, { headers: getHttpHeaders(), responseType: 'blob'}).toPromise().then(response => {
    var segs = filename.split("/");
    var d = new Date();

    this.http.get<any>(url_name, { headers: getHttpHeaders()}).toPromise().then(titre => {
     // console.log(titre);
    saveAs(response, titre.result);
    }).catch(error => {
     
      saveAs(response, d.getTime() + "_" + segs.slice(-1)[0]);
    });
//;
  //  window.open(URL.createObjectURL(new Blob([response], { type: 'application/pdf' })), '_blank');

   }).catch(error => {
      console.log("ERROR=", error);
      notify("Erreur ou virus détecté : abandon " + "[fileId=" + fileId + "]")
    });

  }




  downloadZipArchive(site_id:any ) {

    //const options = new RequestOptions({ responseType: ResponseContentType.Blob });
    const options = {
      headers: new HttpHeaders({
        responseType:  'blob'
        })
      };

    var headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', '*')
      .set('Accept', 'application/octet-stream')
      .set('Access-Control-Allow-Methods', 'GET, POST, OPTIONS, PUT, PATCH, DELETE')
      .set('Access-Control-Allow-Headers', 'X-Requested-With, responseType,Origin, Content-Type,Accept, X-Auth-Token ,App-Auth,X-XSRF-TOKEN,Authorization')
      .set('Access-Control-Allow-Credentials', "true");

    const url = AppConfig.settings.api+ "/api/document/sitezip/" + site_id;
    this.http.get(url, { headers: getHttpHeaders(), responseType: 'blob'}).toPromise().then(response => {

    saveAs(response, (new Date()).getTime() + "_site-doc-archive-" + site_id + ".zip");
   }).catch(error => {
      console.log("ERROR=", error);
    });

  }


  headersAccess : Array<string> ;

  constructor(private http: HttpClient  ) {

    let SERVICE_URL = AppConfig.settings.api + "/api/document/";

    this.dataSource = new CustomStore(
      {
        key: "id",

        load: function (loadOptions: any) :any {

          var filter = "?filter=type=0";
          var usage = 0

          if(this.loadOptions) loadOptions = {...loadOptions, ...this.loadOptions} // <= C'est quoi ce truc ? (elie)
         // console.log("DocumentService ", loadOptions);

          if (loadOptions.filter) {
            if(AppConfig.settings.trace) console.log("doc FILTER=" , loadOptions.filter)

            if(loadOptions.filter[0].length==1) {
              usage = loadOptions.filter.shift()[0];
              if(AppConfig.settings.trace) console.log("doc USAGE=" , usage)
            }
       
            filter = "?filter=" + encodeURIComponent(jsToSQLFilter(loadOptions.filter));
          }
          if (loadOptions.skip) {
            filter = filter + "&skip=" + loadOptions.skip;
          }
          else
            filter = filter + "&skip=0";

          if (loadOptions.take) {
            filter = filter + "&take=" + loadOptions.take;
          }

          if (loadOptions.sort) {
            var usort = "";
            for (var i = 0; i < loadOptions.sort.length; i++) {
              var field = "";
              if (loadOptions.sort[i].selector == "nomReference")
                field = "n.reference.nom";
              else
                field = loadOptions.sort[i].selector;

              usort = usort + field + " " + (loadOptions.sort[i].desc ? "DESC" : "ASC");
              if (i + 1 < loadOptions.sort.length)
                usort = usort + ",";
            }
            filter = filter + "&sort=" + encodeURIComponent(usort);
          }

          if(usage) {
            filter = filter + "&usage=" + encodeURIComponent(usage);
          }

        //  console.log(" DocumentService load: calling url: " + SERVICE_URL + filter);
          if(AppConfig.settings.trace) console.log(" DocumentService load: calling url: " + SERVICE_URL + filter);

          return http.get<any>(SERVICE_URL + filter, { headers: getHttpHeaders() }).toPromise()
            .then(response => {
          if(AppConfig.settings.trace)             console.log("---------------> DocumentService SERVER RESPONSE: ", response);
              return response;

            });
        },

        // totalCount: function(loadOptions:any) { return 0;},

        byKey: function (key) {
          if (key < 0) {
            key = - key;
            this.saveFile(key);
            //return http.get(SERVICE_URL + "/download/" + encodeURIComponent(key)).toPromise();
          } else {
           // console.log("byKey: " + SERVICE_URL + encodeURIComponent(key));

            return http.get(SERVICE_URL + encodeURIComponent(key), { headers: getHttpHeaders() }).toPromise();
          }
        },
        insert: function (values:any) {

          if(AppConfig.settings.trace) {
            console.log("DocumentService CREATION: ", values);
          }
          // Need to create user (password, email)

        //  console.log("insert: post " + SERVICE_URL);
          return http.post<any>(SERVICE_URL, values, { headers: getHttpHeaders() }).toPromise().then(response => {
            values.id = response.key;
            return {
              values: response.values,
              key: response.key
            }
          }).catch(function (err) {
            console.log(err.message)
            notify("Fichier de taille trop importante, quota dépassé ou virus détecté : échec de la mise à jour")
            throw err
          });
        },
        update: function (key, values) {
         // console.log("update put: " + SERVICE_URL + encodeURIComponent(key));
         if(AppConfig.settings.trace) console.log("update values:", values);
          return http.put<any>(SERVICE_URL + encodeURIComponent(key), values, { headers: getHttpHeaders() }).toPromise().then(response => {

            notify('Mise à jour effectuée');
            return {
              result: response.result,
              key: response.key
            }
          }).catch(error=> {
            console.log(error);
            notify("Erreur ou virus détecté : échec de la mise à jour")
            throw error
          });

        },
        remove: function (key) {
          // Need to refresh cache after that ?
        //  console.log("DocumentService remove delete: " + SERVICE_URL + encodeURIComponent(key));
          return http.delete<any>(SERVICE_URL + encodeURIComponent(key), { headers: getHttpHeaders() }).toPromise();
        }
      }



    );











    this.feSiteDataSource = new CustomStore(
      {
        key: "id",

        load: function (loadOptions: any) :any {

          var filter = "toutesfiches";
          //if(AppConfig.settings.trace)   console.log("loadOptions.filter=",loadOptions.filter);

          if (loadOptions.filter) {

            filter = "fichessite/" + encodeURIComponent(loadOptions.filter[0]);

          }
          if (loadOptions.skip) {
            filter = filter + "?skip=" + loadOptions.skip;
          }
          else
            filter = filter + "?skip=0";

          if (loadOptions.take) {
            filter = filter + "&take=" + loadOptions.take;
          }

          if (loadOptions.sort) {
            var usort = "";
            for (var i = 0; i < loadOptions.sort.length; i++) {
              var field = loadOptions.sort[i].selector;
              usort = usort + field + " " + (loadOptions.sort[i].desc ? "DESC" : "ASC");
              if (i + 1 < loadOptions.sort.length)
                usort = usort + ",";
            }
            filter = filter + "&sort=" + encodeURIComponent(usort);
          }

        //  console.log(" DocumentService load: calling url: " + SERVICE_URL + filter);

          return http.get<any>(SERVICE_URL + filter, { headers: getHttpHeaders() }).toPromise()
            .then(response => {
          if(AppConfig.settings.trace)  console.log("---------------> DocumentService feSiteDataSource SERVER RESPONSE: ", response);
              return response;

            });
        },


      }



    );


  }

  getRestDataSource() {
    return this.dataSource;
  }

  getFESiteRestDataSource() {
    return this.feSiteDataSource;
  }


  getDossiersDataSource() {
    return _dossiersDatSource;
  }

  getDossiersClientDataSource() {
    return _dossiersClientDatSource;
  }

}
/*
	NON_DEFINI(0),
	FICHE_ECLAIRAGE_REFERENCE(1), // rattaché à une Reference
	PIECE_JOINTE_EXIGENCEVERSION(2), // rattaché à une Exigence
	PIECE_JOINTE_EXIGENCEFUTURE(3), // rattaché à une Exigence
	DOC_CLIENT_REFERENCE(4), // rattaché à une ReferenceUsage
	DOC_CLIENT_SITE(5), // rattaché à un Site
	DOC_ADMINISTRATEUR_GENERAL(6), // non rattaché, exemple: manuel utilisateur
	RAPPORT_CLIENT(7), // rattaché à un client (dossier rapport ?)
	RAPPORT_ADMIN(8), // rattaché à un client (rapport)
	DOC_CLIENT_AUDIT(9), // rattaché à EvaluationConformite
	DOC_CLIENT_EXIGENCE(10),  // rattaché à une Exigence
	DOC_CLIENT_EVLUATIONEXIGENCE(11),  // rattaché à une EvaluationExigence
	RAPPORT_REPONSE_QUESTIONNAIRE(12), // document synthétisant les réponses à un questionnaire
	PIECE_JOINTE_LETTREMENSUELLE(13), // document unique transmis comme pièce jointe aux lettres mensuelles
	CHAPEAU_LETTREMENSUELLE(14); // document unique transmis comme chapeau/entête aux lettres mensuelles
	*/
