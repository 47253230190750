import { Component, EventEmitter, Injectable, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { informativeStatus, shortStatusesByStatus } from './status/status.const';
import { EvaluationExigenceModel } from './form.model';
import { EvaluationExigenceFormService } from './form.service';
import notify from 'devextreme/ui/notify';
import { EvaluationExigenceService } from 'app/services/evaluation-exigence.service';
import { UtilisateurService } from 'app/services/utilisateur.service';
import { userHeaderInfo } from 'app/services/commons';
import { AdministrateurService } from 'app/services/administrateur.service';
import { DocumentService } from 'app/services/document.service';
import { DxDataGridComponent } from 'devextreme-angular';

@Component({
  selector: 'app-ee-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})

@Injectable()
export class EvaluationExigenceFormComponent implements OnInit, OnDestroy {

  private _currentEE: any
  @Input() @Output() parentData: any
  @Input() @Output() parentDataForTransversalActions: any = undefined
  @Input() superParentData: any
  @Input() hyperParentData: any
  @Input() currentSite: any
  @Input() editMode: number
  @Input() currentReferenceUsage: any
  @Input() container: any
  @Input() minimal: boolean = false
  @Input() auto: boolean = false
  @Input() linkedForm: any
  @Input() multiSite: boolean = false
  @Input() audits: any
  @Input() disabled: boolean = false
  @Input() readOnly: boolean 
  internalDisabled: boolean = false
  // Primitive type so we can't work same as exigence
  private _popUpContainer: boolean
  @Output('onSubmit') submit: EventEmitter<any> = new EventEmitter<any>()

  eeModel: EvaluationExigenceModel
  currentUser: any
  documentSource: any

  @ViewChild('pjGrid') pjGrid: DxDataGridComponent

  constructor(public eeFormService: EvaluationExigenceFormService, public eeService: EvaluationExigenceService,
    public administrateurService: AdministrateurService, public userService: UtilisateurService, public docService: DocumentService) {
      this.documentSource = this.docService.getRestDataSource()
  }

  get currentEE(): any {
    return this._currentEE
  }

  @Input('currentEE') @Output('currentEE') set currentEE(value: any) {
    if(this.auto && this._currentEE.sstatut != 'X' && this.currentEE.sstatut != 'I'){
    //  console.log(`==== About to save previous EE`, this._currentEE)
      // UC: nextExigence, reUsage clicked, etc
      this.onSubmit(undefined, false).then(r => {
          this._currentEE = value
          this.eeModel = new EvaluationExigenceModel().init(this._currentEE, this.linkedForm)
      })
    } else {
      this._currentEE = value
      this.eeModel = new EvaluationExigenceModel().init(this._currentEE, this.linkedForm)

      if(this.eeModel.id != 0){
       // console.log(`=== currentEE: ${JSON.stringify(this.eeModel)}`)
     //   this.documentSource.loadOptions = {filter: [['type', '=' , 11], 'and',['rattache_id', '=' , this.eeModel.id]]}
     //   if(this.pjGrid) this.pjGrid.instance.getDataSource().reload()
     //if(this.pjGrid) this.pjGrid.

     // Méthode correcte (elie) : (sinon cela bloquer ales autre appels depuis audit-editeur.component.ts)
      //this.documentSource.load( { filter: [["type", "=", 11], "and", ["rattache_id", "=", this.eeModel.id]] } )
      this.documentSource.loadOptions = {filter: [['type', '=' , 11], 'and',['rattache_id', '=' , this.eeModel.id]]}
      if(this.pjGrid) this.pjGrid.instance.getDataSource().reload()
      }
    }

  }

  get popUpContainer(): any {
    return this._popUpContainer
  }

  // Launched when dialog box is opened or closed
  // (_currentEE must be set)
  @Input('popUpContainer') set popUpContainer(value: any) {
    this._popUpContainer = value
    // Init model when dialog box is opened or closed
    // FIXME: delete on close?
    //if(this.popUpContainer){
      this.eeModel.init(this._currentEE, this.linkedForm)
    //}
  }

  ngOnInit(): void {
    this.eeModel = new EvaluationExigenceModel().init(this._currentEE, this.linkedForm)
    this.internalDisabled = informativeStatus(this.eeModel.statut)

    if(userHeaderInfo[0] == 'A'){
      this.administrateurService.getRestDataSource().byKey(userHeaderInfo[1]).then(response => {
        this.currentUser = response.fullname
      })
    } else {
      this.userService.getRestDataSource().byKey(userHeaderInfo[1]).then(response => {
        this.currentUser = response.fullname
      })
    }
    if( this.pjGrid &&  this.pjGrid.instance)  
    this.pjGrid.instance.filter( { filter: [["type", "=", 11], "and", ["rattache_id", "=", this.eeModel.id]] } )

  }

  formAttr() {
    return this.popUpContainer !== undefined ? {class: 'genericForm'}: {class: 'genericFormLarger'}
  }

  minimalClassIf(){
    return this.minimal ? 'hiddenFields' : ''
  }

  showButtons(){
    return !this.minimal && !this.auto && !informativeStatus(this.eeModel.statut)
  }

  formButtonsClass(){
    return this.popUpContainer !== undefined ? 'eeFormButtonsFixedGroup' : 'eeFormButtonsGroup'
  }

  showSaveAllButton(){
    return this.currentReferenceUsage !== undefined && this.currentReferenceUsage.eecount > 1
  }

  showSaveAllActionsButton(){
   let r:boolean = this.parentDataForTransversalActions!=undefined && this.parentDataForTransversalActions.length>1
   return r
  }

  // Multi-site
  showSaveAllSiteButton(){
    return this.multiSite && this.container.instance.totalCount() > 1
  }

  onStatusValueChanged(e: any) {
    // Status
    this.eeModel.statut = e.value

    // User Actions to save
    if(this.eeModel.statut == 3 || this.eeModel.statut == 5){
      this.eeModel.userActions.push("aEvaluerOrNonConforme")
    } 
    if((this.eeModel.initialStatut == 3 || this.eeModel.initialStatut == 5) && this.eeModel.statut == 2){
      this.eeModel.userActions.push("leverEcart")
      // UI user
      if(this.eeModel.compliantModel.lastUserLeveeEcart == undefined) this.eeModel.compliantModel.lastUserLeveeEcart = this.getCurrentUserOrAdminAsUser()
    } 
    if(this.eeModel.initialStatut != this.eeModel.statut){
      this.eeModel.userActions.push("changementStatut")
      // UI user
      switch (this.eeModel.statut) {
        //case 1:
          //if(this.eeModel.toEvaluateModel.lastUserStatut == undefined) this.eeModel.toEvaluateModel.lastUserStatut = this.currentUser
          //break;
        case 2:
          if(this.eeModel.compliantModel.lastUserStatut == undefined) this.eeModel.compliantModel.lastUserStatut = this.getCurrentUserOrAdminAsUser()
          break;
        case 3:
          if(this.eeModel.notCompliantModel.lastUserStatut == undefined) this.eeModel.notCompliantModel.lastUserStatut = this.getCurrentUserOrAdminAsUser()
          break;
        case 4:
          if(this.eeModel.withoutObjectModel.lastUserStatut == undefined) this.eeModel.withoutObjectModel.lastUserStatut = this.getCurrentUserOrAdminAsUser()
          break;
        case 5:
          if(this.eeModel.toCheckModel.lastUserStatut == undefined) this.eeModel.toCheckModel.lastUserStatut = this.getCurrentUserOrAdminAsUser()
          break;
      }
    }
  }

  onRevision(e: any){
    // User Actions to save
    this.eeModel.userActions.push("reviser")

    // UI user
    switch (this.eeModel.statut) {
      case 2:
        if(this.eeModel.compliantModel.lastUserRevision == undefined) this.eeModel.compliantModel.lastUserRevision = this.currentUser
        break;
      case 4:
        if(this.eeModel.withoutObjectModel.lastUserRevision == undefined) this.eeModel.withoutObjectModel.lastUserRevision = this.currentUser
        break;
    }
  }

  onOpportunity(e: any){
    // User Actions to save
    if(e.opportunityCreated){
      this.eeModel.userActions.push("creerOpportunite")
      // UI user
      if(this.eeModel.compliantModel.lastUserOpportunity == undefined) this.eeModel.compliantModel.lastUserOpportunity = this.currentUser
    } else if(e.opportunityRealized){
      this.eeModel.userActions.push("realiserOpportunite")
      // UI user
      if(this.eeModel.compliantModel.lastUserOpportunityRealized == undefined) this.eeModel.compliantModel.lastUserOpportunityRealized = this.currentUser
    } else if(e.opportunityCanceled){
      this.eeModel.userActions = this.eeModel.userActions.filter(function(value, index, arr){ 
        return value == 'realiserOpportunite'
      })
      // UI user
      this.eeModel.compliantModel.lastUserOpportunity = undefined
    }
  }

  onDataFieldChange(e: any, eeForm: any){
    eeForm[e.field] = e.value
  }

  // isDirty(_eeModel: any){
  //   console.log(`======= IS DIRTY?: ${!isEqual(_eeModel, new EvaluationExigenceModel().init(this._currentEE, undefined).flatten())}`)
  //   console.log(`======== MODEL: ${JSON.stringify(_eeModel)}`)
  //   console.log(`======== MODEL: ${JSON.stringify( new EvaluationExigenceModel().init(this._currentEE, undefined).flatten())}`)
  //   return !isEqual(_eeModel, new EvaluationExigenceModel().init(this._currentEE, undefined).flatten())
  // }

  // TODO: make a DTO layout?
  async onSubmit(e: any, all: boolean) {
    let _model: any 

    // An exigence must be selected
    if (this.eeModel.id != 0 || this.eeModel.statut !== undefined){ 
      _model = this.eeModel.flatten()

      // #397
      _model.isAdminAsUser = sessionStorage.getItem("adminAsUser") != undefined

      switch(this.eeModel.statut){
        case 2:
          if(this.eeModel.compliantModel.efficacite != this._currentEE.efficacite){
            this.eeModel.compliantModel.dateSaisieEfficacite = new Date() // For submitAll
            _model.dateSaisieEfficacite = new Date()
          }
          break;
        case 3: 
          _model.notifiyResponsible = this.eeModel.notCompliantModel.notifyResponsible
          break;
        case 5: 
          _model.notifiyResponsible = this.eeModel.toCheckModel.notifyResponsible
          break;
        default:
          break;
      }
      // Model is undefined for Informative status
      if(_model !== undefined /**&& this.isDirty(_model)*/){

        this._currentEE = await this.eeFormService.save(_model)

        // Update parent model:
        if(this.parentData !== undefined){
          // 'Veille' case
          if(this.parentData.evaluationExigence !== undefined){
            this.parentData.evaluationExigence = this._currentEE
            this.parentData.evaluationExigence.sstatut= shortStatusesByStatus[this.currentEE.statut];
            } else if(this.auto && this.parentData.instance !== undefined 
                && this.parentData.instance.getDataSource() !== undefined && this.parentData.instance.getDataSource() !== null){
            // Audit case: parentData = exigenceGrid
            this.hyperParentData.instance.refresh(true)
            this.superParentData.instance.refresh(true)
            this.parentData.instance.refresh(true)
          } else {
            // 'Audit' tab cases - single ee save
            if(!all && this.container !== undefined && this.container.instance.getDataSource() !== null){
              this.container.instance.getDataSource().reload()
              this.container.instance.clearSelection();
            //  this.container.instance.refresh(); // provoque double appel avec un bug au second appel
            }
          }
        } else {
          // Multi site case
          if(this.multiSite){
            this.container.instance.getDataSource().reload()
          }
        }


        // Notify reponsible if any
        if(_model !== undefined && _model.notifiyResponsible){
          this.notifyResponsible()
        }
      }
      if(!all){
        // Close dialog box
        this.submit.emit({id: this.eeModel.id, value: false})
      }
}

    return _model == undefined ? {result: false} : {result: true}
  }

  async onSubmitAll(e: any, all: boolean) {
    notify("Veuillez patienter, sauvegarde des évaluations en cours...", "info", 550)

    for(let el of this.parentData){
      this.eeModel.id = el.id
      await this.onSubmit(e, true)
    }

    // Close dialog box
    this.submit.emit({value: false})
    // Refresh
    this.container.instance.getDataSource().reload()
    this.container.instance.clearSelection();

    //this._dirty = false
  }

  async onSubmitAllForActions(e: any) {
    notify("Veuillez patienter, sauvegarde des évaluations en cours...", "info", 550)

    for(let el of this.parentDataForTransversalActions){
      this.eeModel.id = el.id
      await this.onSubmit(e, true)
    }

    // Close dialog box
    this.submit.emit({value: false})
    // Refresh
    this.container.instance.getDataSource().reload()
    this.container.instance.clearSelection();

  }

  


  onSubmitSiteAll(e: any, all: boolean, onlyAevaluer: boolean){
    this.onSubmit(undefined, all).then(r => {
      // Close dialog box
      this.submit.emit({value: false})
      // Propagate to other Audits
      this.eeService.propagateEE2AutresSites(this._currentEE.id, this.audits, onlyAevaluer).then(r => {
        this.container.instance.getDataSource().reload();
        notify("Propagé aux autres audits, " + (onlyAevaluer ? "aux seules exigences restant à évaluer" : "") + " " + r + " évaluation(s) impactée(s)");
      });
    });

  }

  onCancel(e: any) {
    //this._dirty = false
    this.eeModel = new EvaluationExigenceModel().init(this._currentEE, this.linkedForm)
  }

  notifyResponsible(){
    this.eeFormService.notifiyResponsible(this.eeModel)
  }

  getCurrentUserOrAdminAsUser(){
    return sessionStorage.getItem("adminAsUser") != undefined ? sessionStorage.getItem("adminAsUser") : this.currentUser
  }

  pjGrid_onRowInserting(e: any) {
    if(this.currentEE){
      e.data.type = 11
      e.data.rattache_id = this.currentEE.id
      e.data.site_id = this.currentSite;
    }
  }

  ngOnDestroy(){
    if (this.minimal || (this.auto && this.currentEE.sstatut != 'I')){ 
    //  console.log(`===== save on destroy`)
        this.onSubmit(undefined, false)   
    }
  }

  downloadDocumentButtonClicked(id: any, filename: any) {
    this.docService.downloadFile(id, filename);
  }
}
